import { Link } from '@remix-run/react';
import background from '../../images/background-no-ellipses.webp';
import logo from '../../images/logo-wide.svg';
import LayoutPaddingX from './LayoutPaddingX';

interface FullPageBackgroundLayoutProps {
  children: React.ReactNode;
}

export default function FullPageBackgroundLayout({
  children,
}: FullPageBackgroundLayoutProps) {
  return (
    <div className="h-screen w-screen flex flex-col">
      <LayoutPaddingX className="bg-white pb-8 shadow-md">
        <header className="flex flex-col pt-8 sm:pt-10">
          <nav className="flex justify-between items-center">
            <div>
              <Link to="/">
                <img src={logo} alt="cardonomics logo" />
              </Link>
            </div>
          </nav>
        </header>
      </LayoutPaddingX>
      <div className="flex flex-grow w-full justify-center items-center p-5">
        <div className="absolute top-0 right-0 left-0 z-[-1] w-full h-full overflow-hidden">
          <img
            src={background}
            alt="background"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="max-w-full overflow-auto bg-white rounded-lg py-12 px-6 sm:px-20 shadow-md border">
          {children}
        </div>
      </div>
    </div>
  );
}
